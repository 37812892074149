.navigation {
    position: fixed;
    left: -40rem;
    width: 20rem;
    height: 100%;
    background: linear-gradient(to top, rgba(34,32,41,.8),rgba(34,32,41,1));
    /* , url(../img/nav-bg.jpeg) center no-repeat;     */
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 5;
    transition: left .8s cubic-bezier(1,0,0,1);
}

.navigation.checked {
    left: 0rem;
}

.navigation__heading{
    /* font-family: 'Josefin Sans', sans-serif; */
    font-size: 3.5rem;
    font-weight: 300;
    letter-spacing: .2rem;
    color: #fff;
    text-align: center;
}

.navigation__list {
    
    list-style: none;
    padding: 0;
    /* height: 10rem; */
    top:0;
    display: flex;
    flex-direction: column;
    justify-content: first baseline;
    align-items: center;
}


.navigation__item{
    position: relative;
}



.navigation__link {
color: #fff;
font-size: 1.6rem;
font-weight: 400;
letter-spacing: .1rem;
text-transform: uppercase;
text-decoration: none;
transition: color .5s;
}

.navigation__Link__Small  {
    font-size: 1rem;
   }

.navigation__link::before{
content: "";
width: 0rem;
height: .1rem;
background-color: #deaa86;
position: absolute;
top:1rem;
left: -4rem;
transition: width .5s cubic-bezier(1,0,0,1);
}


.navigation__link::after{
content: "";
width: 0rem;
height: .1rem;
background-color: #deaa86;
position: absolute;
top:1rem;
right: -4rem;
transition: width .5s cubic-bezier(1,0,0,1);
}


.navigation__Link__Small::before,
.navigation__Link__Small::after {
    top: 0.6rem;
}

.navigation__link:hover{
    
    color:  #deaa86;
    /* font-size: x-large; */
}

.navigation__link:hover::before,
.navigation__link:hover::after{
    width: 3rem;
}

.copywrite {
    color: #fff;
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
}

.social__icons__list {
    list-style: none;
    padding: 0;
    bottom: 10rem;
    display: flex;
    flex-direction: row;    
    justify-content: space-evenly;
    /* transform: translateY(-5%); */
    align-items: end;
    gap: 2rem;
}

.social__link{
    font-size: 1.6rem;
    color: #777;
    transition: color .3s;
    display: inline;
}



.social__link:hover{
    color: #deaa86;
}