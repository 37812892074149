.wrapper{
    background:  linear-gradient(45deg, rgb(156, 14, 14), rgb(228, 173, 91) );
    border-radius: 10px;
    display: block;
}

.timeline{
margin: 0 auto;
max-width: 90vw;
padding: 30px;
display: grid;
grid-template-columns: 1fr 3px 1fr;
color: wheat;

}

.component{
    margin: 0 20px 20px 20px;
    overflow: hidden;
    /* width:  40vw; */
    height: 18vh;    
    display: inline-block;
    text-overflow: ellipsis;
    
}

.component p {
    word-wrap: break-word;
}

/* .component p:after {
    content: "\A";
    white-space:pre;
  } */

.componentbackground{
    
    padding: 1.5em;
    background: rgba(255,255,255,0.2);
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
    border-radius: 10px;
    margin-left: 20px;
    }




.title{
    font-weight: bolder;
    margin: 0;
}

.dateright{
    
margin-left: 20px;
text-align: left;
}

.dateleft{
margin-right: 20px;
text-align: right;
}

.middle{
    position: relative;
    background-color: white;
}

.point{
    position: absolute;
    top:0;
    left: 50%;
    transform: translateX(-50%);
    width: 15px;
    height: 15px;
    background:white;
    border-radius: 50%;
}

.point .bottom{

    margin-bottom: 0;
}

.postionLeft{
   display: inline-block; 
	text-align: left;   

}

.postionRight li {
    list-style-type: none;
}

.postionLeft li{
    margin-right: 20px;
text-align: right;
list-style-type: none;



}

