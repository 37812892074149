.timeline__item {
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  max-height: 30rem;
  background-color: #fff;
  position: relative;
  /* margin-bottom: 2rem; */
  border-radius: 1rem;
  border: 0.1rem solid #fff;
  height: 13rem;
  width: 50rem;
  
}

.img {
  width: 3.5rem;
  height: 3.5rem;
  /* transform: translate(48.5rem, -9.5rem); */
  transform: translateX(48.5rem);
  z-index: 15;
  position: absolute;
}

.timeline__item::before {
  content: "";
  position: absolute;
  width: 4rem;
  height: 4rem;
  right: -2.5rem;
  background-color: white;
  border: 4px solid #ff9f55;
  top: -0.5rem;
  border-radius: 50%;
  z-index: 1;
}

.timeline__item::after {
  content: "";
  position: absolute;
  width: 0.5rem;
  background-color: #fff;
  top: 0.9rem;
  bottom: -6rem;
  right: -1.3rem;
  z-index: 2;
}

.timeline__last::after {
  width: 0;
  
}

.navigation__link {
    margin-left: auto;
    position: relative;
    margin-bottom: 2rem;
    margin-top: auto;
    margin-right: 2rem;
}

.navigation__link a {
color: black;
}

.timeline__item__header {
  grid-area: header;
  align-items: center;
  display: grid;
  justify-content: center;
  width: 100%;
  font-weight: bolder;
}

.timeline__item__sidebar {
  grid-area: sidebar;
  display: flex;
  justify-content: center;
  
  align-items: center;
}

.timeline__item__main {
  grid-area: main;
  width: 50rem;
  /* display: flex; */
}

.positions{
    list-style: circle;
    display: grid;
    justify-content: center;
}

.datefrom::after {
    content: "-";
    position: relative;
    width: 15rem;
}


@media (max-width: 850px) {
  .timeline__item {
    max-width: 20rem;
    width: 20rem;
    max-height: 50rem;
  }

  .timeline__item__header {
    width: 20rem;
  }
  
.timeline__item__main{
    width: 15rem;
}



.timeline__item__sidebar{
    font-size: small;
    
}


  .img {
    width: 3.5rem;
    height: 3.5rem;
    
    transform: translateX(18.5rem);
    z-index: 15;
  }

  .positions{
    justify-content: left;
  }


}


@media (max-width:360px) {
    
  .timeline__item {
    max-width: 15rem;
    width: 15rem;
    max-height: 15rem;
    /* font-size: x-small; */
  }
  
.timeline__item__header{
  max-width: 15rem;
}
  .img {
    width: 3.5rem;
    height: 3.5rem;
    
    transform: translateX(13.5rem);
    z-index: 15;
  }

  .timeline__item__sidebar{
    font-size: x-small;
    
}

.positions  {
  justify-content: left;
}

  }