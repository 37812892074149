.Page{
    /* display: inline-flex;
    position: relative;
    top:10vh;
    width: 100vw;
    height: 90vh; */
    position: relative;
    top: 15vh;
    z-index: -1;
    left: 2vw;
    right: 2vw;
    width: 96vw;
}

@media screen and (max-width:500px) {
    .Page{
        top: 12vh;
    }
}