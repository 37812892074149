

.wrapper{
  display: grid;   
  grid-template-columns: repeat(4,0.5fr);

  /* justify-content:space-around; */
  position: relative;
  /* column-gap: 0%; */
  row-gap: 4rem;
  overflow: hidden;  
  justify-items: center;
  padding: 0;
  padding-top: 5rem;
  padding-bottom: 0;
    
}

.skills{
  /* background: linear-gradient(to bottom, rgba(243, 241, 241, 0.1),rgba(255, 254, 254,1)); */
}

.skills__header{
  margin-left: 2rem;
  text-align: center;
  position: relative;
}

.skills__description{
  
  margin-bottom: 2rem;
}

.skill__item{

  height: 15rem;
  width: 15rem;
  border-radius: 50%;
  display: flex; 
  align-items: center; 
  justify-content: center;
  background-color: #bbb;
  color: #fff;
  font-weight: bold;
  box-shadow:0 1.5rem 3rem rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  animation: anim 2s;
  overflow: hidden;
}

.wrapper:last-child{
  padding-bottom: 5rem;
}






@keyframes anim {
  0% {
      opacity: 0;
      transform: translatey(30px);
  }

  50% {
    opacity: 0.5;
    transform: translatey(-30px);
  }
  100% {
      opacity: 1;
  }
}


@media(max-width:1400px){
  .wrapper{
    grid-template-columns: auto auto auto;
  }

  
}


@media(max-width:870px){
  .wrapper{
    grid-template-columns: auto auto;    
    padding-top: 0;
  }

  .skill__item{

box-shadow:0 1.5rem 0.5rem rgba(0, 0, 0, 0.5);
  }
}

@media(max-width:800px){
  .skills__description{
    font-size: small;
  }
}

@media(max-width:600px){
 
 
  .skill__item{

    height: 10rem;
    width: 10rem;
    border-radius: 50%;
  
  }

}

@media(max-width:360px){
 
 
  .skill__item{

    height: 8rem;
    width: 8rem;
    border-radius: 50%;
  
  }

}