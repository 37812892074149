.timeline{
    display: grid;
    justify-content: center;
    position: relative;
    overflow: visible;
    row-gap: 3.5rem;
    padding-bottom: 10rem;
}


.title {
    margin-left: 2rem;
    text-align: center;
    align-content: center;
    width: 100%;
    margin-left: 0;
    padding-top: 2rem;
}

.description{
    text-align: center;
    margin-bottom: 2rem;
    margin-left: 2rem;
    margin-right: 2rem; 
}

@media(max-width:800px){
    .description{
      font-size: small;
    }
  }