.container {
    
  /* height: 100vh; */
  
}

.header {
  background: url("/public/Images/corbiere.jpg"), center no-repeat;
  height: 25vh;
  position: relative;
  width: 100%;
  background-position: center;
  background-size: cover;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.heading {
  font-size: 5rem;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 0.5rem;
  animation: About_anim__LF470 2s;
  color: #fff;

}

@media (max-width:360px) {
    
  .heading{
      font-size: 4rem;
   
  }
  
  }