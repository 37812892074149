.container {
    
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    
}

.header {
    background: url("/public/Images/corbiere.jpg"), center no-repeat;
    height: 25vh;
    position: relative;
    width: 100%;
    background-position: center;
    background-size: cover;
    text-align: center;
    display: flex;
    flex-direction: column;
}


.heading {    
    font-size: 5rem;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 0.5rem;
    animation: anim 2s;    
    
    color: #fff;
    justify-self: center;
    
    
  }
 

.about {
    flex: 0 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
    margin-right: 2rem;
    box-sizing: border-box;
    
    height: 50%;
}

.about p {
    justify-self: left;
    align-items: center;
    text-align: justify;
    
}

.about h2 {
    text-align: center;
}

.skills {
    /* background-color: #358d23; */
    /* height: 100%; */
    overflow: hidden;
    /* padding: 1rem 1rem 4rem 1rem; */
    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
    margin-right: 2rem;
    box-sizing: border-box;
    
    
    
}

.employment {
    background-color: orange;
    overflow: visible;
    position: relative;   
  
}


@media (max-width:800px) {
    
.about p {
    font-size: small;
    
}

}

@media (max-width:360px) {
    
.heading{
    font-size: 4rem;
   
}

}