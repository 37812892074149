.sidebar{
    width: 2.5rem;
    height: 100%;
    background-color: #eee;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    background: transparent;
}

.menu__icon{    
    margin-top: 3rem;
    cursor: pointer;
}

.menu__icon:hover .line{
    width: 2.5rem;
}

.line{
    height: .2rem;
    width: 2.5rem;
    background-color: #fff;
    margin-bottom: .6rem;
    margin-left: .6rem;
    transition: background-color 2s;
}

.line__1, .line__3{
    width: 1.8rem;
    transition: all .4s;
}



.year {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: #fff;
    margin-left: 1.6rem;
}




.line.dark {
    background-color: #000;
}


.line__1.checked {
    transform: rotate(-405deg) translate(-10px, 5px);
   width: 2.5rem;
   background-color: rgba(255,255,255, 0.8);
}

.line__2.checked {
    opacity: 0;
}

.line__3.checked {
    transform: rotate(405deg) translate(-12px, -8px);
   width: 2.5rem;
   background-color: rgba(255,255,255, 0.8);
}

@media (max-width:800px) {

    .menu__icon{
        margin-top: 1rem;
    }
    
}