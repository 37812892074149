.BackGround{
    background-image: url("/public/Images/corbiere.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
}

.Card{
    top: 51vh;
    left: 43vw;
    color: white;
    height: 10vh;
  
    transition: 0.3s;    
    text-align: center;
    position: absolute;

}

.Name{    
   
    font-size: xx-large;
    color: white;
}

@media only screen and (max-width: 600px) 
{
    .BackGround{
        background-image: url("/public/Images/corbiere.jpg");
        background-size: cover;
    }

    .Card{
        top: 40vh;
        left: 10vw;
    
    }


    .Name{     
        font-size: xx-large;
    }
}
@media only screen and (max-width: 900px) 
{
    /* .BackGround{
        background-image: url("/public/Images/small/corbiere.jpg");
    } */

    .Card{
        top: 40vh;
        left: 10vw;        
        height: 10vh;        

    
    }


    
}