.company__wrapper {
  display: flex;
  flex-direction: column;  
  margin-left: 2rem;
  margin-right: 2rem;
}

.company__details {
  
  display: grid;
   grid-template-columns: repeat(6,1fr);
   grid-template-rows: 1fr;
    grid-template-areas: ".  . companyname companyname  . logo";

  
}

.companyName{
  display: grid;
  grid-area: companyname;
}

.company__details h1 {
  
  
  justify-content: center;
  text-align: center;
 
}



.companyLogo {
  /* display: inline-block; */
  /* width: 100%; */
  grid-area: logo;
}

.companyLogo img {
  width: 5.5rem;
  height: 5.5rem;
  
  justify-content: center;
  display: flex;
  
   margin-left: auto;  
  
  
}


.paragraphs {
  
  overflow-wrap: break-word;
  position: relative;
  
  text-align: justify;
}

.skills {
  
}


.dates {
  display: flex;
  justify-content: center;
  align-items: center;
}

.datefrom::after {
  content: "-";
  position: relative;
  width: 15rem;
}

.positions {
  list-style: circle;
  display: grid;
  justify-content: center;
}

/* @media (max-width: 850px) {
  .company__details h1 {
    margin-left: 2rem;
  }

  .paragraphs {
   
    overflow-wrap: break-word;
    word-wrap: break-word;
    position: relative;    
    text-align: left;
  }
} */
@media (max-width:360px) {
    
.company__details {
  
  display: grid;
   grid-template-columns: repeat(4,1fr);
   grid-template-rows: 1fr;
    grid-template-areas: "companyname companyname  companyname logo";

  
}


/* .companyLogo img {
  width: 3rem;
  height: 3rem;
  
  justify-content: center;
  display: flex;
  
   margin-left: auto;  
  
  
} */


.companyName{
  justify-self:  center;
}

}