/* 
:root{
    --white: #f9f9f9;
    --black: #36383F;
    --gray: #85888C;
}

.SideMenu{
    position: relative;
    float: right;
}



.LinkMenu{
    position: relative;
    float: right;   
    
}

.LinkMenu a{
    display: inline-block;
    padding: 20px;
    text-decoration: none;
}

a:link{
    color: white;
    text-decoration: none;
  }
  
  a:visited{
    color: white;
  }
  
  a:active{
    color: white;
  }

.LinkMenu a:hover{
background-color: var(--gray);
} */

.NavMenu{
position: fixed;
top:0;
width: 100vw;
height: 50px;
background:  linear-gradient(45deg, rgb(156, 14, 14), rgb(228, 173, 91) );

}

.MenuItemList{
list-style-type: none;
background:  linear-gradient(45deg, rgb(156, 14, 14), rgb(228, 173, 91) );

height: 100%;
display: flex;
align-items: center;
position: relative;
z-index: 100;
}

.MenuItem{
margin-right: 20px;
font-size: 20px;
color: white;
cursor: pointer;
text-transform: uppercase;
z-index: 100;
}

.MenuItem a{
    display: inline-block;
    /* padding: 20px; */
    text-decoration: none;
}


.button {
    display: none;
    position: absolute;
    right: 10px;
    top: 7px;
    color: white;
    font-size: 18px;
    background-color: transparent;
    border: none;
}

.selected{
 font-weight: bold;
}

a:link{
    color: white;
    text-decoration: none;
}
  
a:visited{
    color: white;
}
  
a:active{
    color: white;
}


  @media screen and (max-width:500px) {
    
    .MenuItemList{
        flex-direction: column;
        height: auto;
    }

    .MenuItem:nth-child(1){
        border-top: 1px solid #fff;
        margin-top: 50px;
    }

    .MenuItem{
        text-align: left;
        border-top: 1px solid #fff;
        width: 100vw;
        margin-right: 0px;
        padding: 20px 0;
        /* z-index: 100; */
    }

    .button{
        display: block;
        z-index: 100;
    }

  }