:root {
  --content-width: calc(40vw - 84px);
  --margin: 20px;
  --spacing: 20px;
  --bdrs: 19px;
  --circle-size: 40px;
  --icon-size: 32px;
  --bdrs-icon: 100%;

  --color1: rgb(156, 14, 14);
}

.employmentevent {
  position: relative;
  display: flex;
  margin: 10px 0;
  align-self: center;
  border-radius: 0 var(--bdrs) var(--bdrs) 0;
}

.employmentevent:nth-child(even) {
  flex-direction: row-reverse;
  .employmentDate {
    border-radius: 0 var(--bdrs) var(--bdrs) 0;
  }
}

.employmentevent:nth-child(even) .content {
  border-radius: var(--bdrs) 0 0 var(--bdrs);
}

.employmentevent:nth-child(even) .eventIcon::before {
  content: "";
  width: 2px;
  height: 100%;
  background: var(--color1);
  position: absolute;
  top: 0%;
  left: 50%;
  right: auto;
  z-index: -1;
  transform: translateX(-50%);
  animation: fillTop 2s forwards 2s ease-in-out;
}

.employmentevent:nth-child(even) .eventIcon::after {
  content: "";
  width: 100%;
  height: 2px;
  background: var(--color1);
  position: absolute;
  right: 0;
  z-index: -1;
  top: 50%;
  left: auto;
  transform: translateY(-50%);
  animation: fillLeftOdd 2s forwards 2s ease-in-out;
}

.employmentevent .title {
  font-size: 1.2rem;
  line-height: 1.4;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  color: color;
  letter-spacing: 1.5px;
  margin-top: 2px;
}

.content {
  padding: 20px;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  background: #fff;
  width: calc(40vw - 84px);
  border-radius: 0 var(--bdrs) var(--bdrs) 0;
  height: 15vh;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
}

.description {
  height: 10vh;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
}

.content a {
  color: black;
  cursor: pointer;
}

.employmentDate {
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  background: var(--color1);
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0 20px;
  border-radius: var(--bdrs) 0 0 var(--bdrs);
  flex-direction: column;
}

.position {
  font-size: 1.5rem;
  font-weight: 600;
}

.eventIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color1);
  padding: 5px;
  align-self: center;
  margin: 0 20px;
  background: var(--color1);
  border-radius: 100%;
  width: 5px;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  padding: 40px;
  height: 5px;
  position: relative;
}

.eventIcon::before {
  content: "";
  width: 2px;
  height: 100%;
  background: var(--color1);
  position: absolute;
  top: 0%;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
  animation: fillTop 1s forwards 2s ease-in-out;
}

.eventIcon::after {
  content: "";
  width: 100%;
  height: 2px;
  background: var(--color1);
  position: absolute;
  left: 0%;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  animation: fillLeft 1s forwards 1s ease-in-out;
}

.employmentevent:last-child :before {
  content: none;
}

.companylink{
    color: pink;
    cursor: pointer;;
}

@keyframes fillLeft {
  100% {
    /* right: 100%; */
    left: -100%;
  }
}

@keyframes fillTop {
  100% {
    top: 100%;
  }
}

@keyframes fillLeftOdd {
  100% {
    left: 100%;
  }
}
